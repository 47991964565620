import Immutable from "seamless-immutable"
import {
  FETCH_FILTERS,
  FETCH_FILTERS_OK,
  FETCH_FILTERS_ERROR,
  CHANGE_FILTERS,
  RESET_FILTERS,
} from "../actions/filterOptionActions"

const initialState = Immutable({
  loading: false,
  data: {},
  error: null,
  filters: { region: "%", year: 0, industry: "%" },
})

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_FILTERS:
      return state.merge({ loading: true })
    case FETCH_FILTERS_OK:
      return state.merge({ data: action.data, loading: false })
    case FETCH_FILTERS_ERROR:
      return state.merge({ error: action.error, loading: false })
    case CHANGE_FILTERS:
      return state.merge({ filters: action.filters })
    case RESET_FILTERS:
      return state.merge({ filters: initialState.filters })
    default:
      return state
  }
}
