import * as api from "../services/api"
import { formatNumber } from "../util/string"

export const GLOBAL_SEARCH = "GLOBAL_SEARCH"
export const GLOBAL_SEARCH_OK = "GLOBAL_SEARCH_OK"
export const GLOBAL_SEARCH_ERROR = "GLOBAL_SEARCH_ERROR"
export const FILTER_CHANGE = "FILTER_CHANGE"
export const STATE_CHANGE = "STATE_CHANGE"
export const SEARCH_STRING_CHANGE = "SEARCH_STRING_CHANGE"

export const globalSearch = (searchString, filters) => async dispatch => {
  dispatch({ type: GLOBAL_SEARCH })

  let flatFilters = {}

  if (filters && typeof filters === "object") {
    Object.keys(filters)
      .map(k => {
        if (filters[k].length && typeof filters[k] === "object") {
          if (k === "personnel") {
            flatFilters["personnels"] = []
            return filters[k].forEach(f =>
              flatFilters["personnels"].push(`${f.value.minPersonnel},${f.value.maxPersonnel}`)
            )
          }
          if (k === "revenue") {
            flatFilters["revenues"] = []
            return filters[k].forEach(f =>
              flatFilters["revenues"].push(`${f.value.minRevenue},${f.value.maxRevenue}`)
            )
          }
          flatFilters[k] = filters[k].map(it => {
            if (typeof it === "object") return it.value
          })
        } else {
          flatFilters[k] = filters[k]
        }
      })
      .filter(it => it)
  }

  const response = await api.searchByValue(searchString || "-", flatFilters)

  if (response.ok) {
    dispatch({
      type: GLOBAL_SEARCH_OK,
      data: response.data,
      value: searchString,
    })
  } else {
    dispatch({
      type: GLOBAL_SEARCH_ERROR,
      error: response.data,
    })
  }
}

export const handleChange = searchString => async dispatch => {
  dispatch({ type: SEARCH_STRING_CHANGE, searchString })
}

export const handleFilterChange = newFilters => async dispatch => {
  dispatch({ type: FILTER_CHANGE, newFilters })
}

export const clearFilters = (prevState, filter) => async dispatch => {
  let newState: {}
  switch (filter) {
    case "region":
      newState = prevState.merge({ filters: { region: [] } }, { deep: true })
      break
    case "industry":
      newState = prevState.merge({ filters: { industry: [] } }, { deep: true })
      break
    case "service":
        newState = prevState.merge({ filters: { service: [] } }, { deep: true })
        break
    case "personnel":
      newState = prevState.merge({ filters: { personnel: [] } }, { deep: true })
      break
    case "form":
      newState = prevState.merge({ filters: { form: [] } }, { deep: true })
      break
    case "revenue":
      newState = prevState.merge({ filters: { revenue: [] } }, { deep: true })
      break
    case "founded":
      newState = prevState.merge(
        { filters: { startYearFrom: "", startYearTo: "" } },
        { deep: true }
      )
      break
    default:
      newState = prevState.merge(
        {
          filters: {
            region: [],
            industry: [],
            service: [],
            personnel: [],
            form: [],
            revenue: [],
            startYearFrom: "",
            startYearTo: "",
            companies: true,
            branches: true,
            active: true,
          },
          searchString: "",
        },
        { deep: true }
      )
  }
  dispatch({ type: STATE_CHANGE, newState })
}

export const setState = newState => async dispatch => {
  dispatch({ type: STATE_CHANGE, newState })
}

// Populates this.state.filters using the current search URI
export const populateStateWithQuery = location => async dispatch => {
  const splitQueryStrings = location.search.split("&").map(string => decodeURI(string))
  const keywords = {
    "region[]": "region",
    "industry[]": "industry",
    "service[]": "service",
    "personnels[]": "personnel",
    "form[]": "form",
    "revenues[]": "revenue",
  }
  const newFilters = {
    region: [],
    industry: [],
    service: [],
    personnel: [],
    form: [],
    revenue: [],
    startYearFrom: "",
    startYearTo: "",
    companies: true,
    branches: true,
    active: true,
  }

  let searchString = ""
  // When no search string is given, '-' is used to get all results
  if (location.pathname.startsWith("/haku/")) {
    searchString =
      location.pathname.replace("/haku/", "") === "-" ? "" : location.pathname.replace("/haku/", "")
  }

  // Check if any query params
  if (splitQueryStrings[0].length > 1) {
    splitQueryStrings.forEach(query => {
      // Split query param into key and value; remove '?' from the first query
      const splitSingleQuery = query.replace("?", "").split("=")
      // Check for cases that need their own handling; add queries as filters accordingly
      if (splitSingleQuery[0] === "startYearFrom" || splitSingleQuery[0] === "startYearTo") {
        newFilters[splitSingleQuery[0]] = splitSingleQuery[1].toString()
      } else if (splitSingleQuery[0] === "revenues[]") {
        const revenues = splitSingleQuery[1].split(",")
        newFilters.revenue.push({
          value: { minRevenue: Number(revenues[0]), maxRevenue: Number(revenues[1]) },
          label: revenues.map(r => formatNumber(r)).join(" - "),
        })
      } else if (splitSingleQuery[0] === "personnels[]") {
        const revenues = splitSingleQuery[1].split(",")
        newFilters.personnel.push({
          value: { minPersonnel: Number(revenues[0]), maxPersonnel: Number(revenues[1]) },
          label: revenues.map(r => formatNumber(r)).join("-"),
        })
      } else {
        newFilters[keywords[splitSingleQuery[0]]].push({
          value: splitSingleQuery[1],
          label: splitSingleQuery[1],
        })
      }
    })
  }
  await dispatch(globalSearch(searchString, newFilters))
  await dispatch(setState({ searchString, filters: newFilters }))
}
