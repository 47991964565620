import React, { Component } from "react"
import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from "victory"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import LoadingSpinner from "../../LoadingSpinner"
import DashboardCard from "../../DashboardCards/DashboardCard"
import colorMap from "../colorMap"
import NotAvailable from "../../NotAvailable"
import { CHART_ANIM } from "../../../util/constants"
import LoadingOverlay from "../../LoadingOverlay"
import InfoModal from "../../InfoModal"
import { ChartTable } from "../../ChartTable"
import Styles from "./CompanySizeDistribution.module.css"
class CompanySizeDistribution extends Component {
  state = {
    style: {
      data: {
        fill: ({ datum }) => datum.color,
      },
    },
  }

  getColumns = () => [
    { id: "y", heading: "Yritysten määrä" },
    { id: "size", heading: "Yrityksen koko" },
  ]
  renderSizeDistribution() {
    const { style } = this.state
    const { loading, companySizes } = this.props

    if (!companySizes) {
      return null
    }

    if (!companySizes.total.length) {
      return <NotAvailable label="Tietoja ei ole saatavilla näillä suodatusvalinnoilla!" />
    }

    const data = companySizes.total
      .filter(f => f.size !== "Ei tiedossa")
      .map(({ amount: y, size }, x) => ({
        x: x + 1,
        y,
        size,
        label: y,
        color: colorMap[x],
      }))

    return (
      <div className={Styles.wrapper}>
        <LoadingOverlay visible={Boolean(loading && companySizes)} />
        <VictoryChart
          height={350}
          padding={{ top: 15, right: 50, bottom: 50, left: 57.5 }}
          width={400}
          domainPadding={50}
          style={{
            parent: {
              height: "auto",
              border: "none",
              padding: "0 2rem",
            },
          }}
        >
          <VictoryAxis
            crossAxis
            style={{
              axis: { stroke: "#E7E7E7", strokeWidth: "0.5px" },
              tickLabels: { fill: "#343434" },
            }}
            tickCount={3}
            tickValues={["Mikroyritys", "Pk-yritys", "Suuryritys"]}
          />
          <VictoryAxis
            dependentAxis
            animate={CHART_ANIM}
            style={{
              grid: { stroke: "#E7E7E7", strokeWidth: "0.5px" },
              axis: { stroke: "transparent" },
            }}
            tickCount={6}
          />
          <VictoryBar
            animate={CHART_ANIM}
            labelComponent={
              <VictoryTooltip
                cornerRadius={0.8}
                flyoutStyle={{ stroke: "grey", strokeWidth: 0.5, fill: "white" }}
                pointerLength={20}
                centerOffset={{ x: 20 }}
              />
            }
            labels={({ datum }) => datum.y}
            barRatio={1.3}
            style={style}
            data={data}
          />
        </VictoryChart>
        <ChartTable title="Yrityskokojakauma" data={data} columns={this.getColumns()}>
          <p>
            Yrityskokojakauma esittää kunnittain, tilinpäätösvuosittain ja toimialoittain eri
            yrityskokojen kokonaismäärän seuraavasti:
            <br />
            <br />
            Mikroyritys: Alle 10 työntekijää
            <br />
            <br />
            PK-yritys: 10 - 249 työntekijää
            <br />
            <br />
            Suuryritys: Yli 250 työntekijää
            <br />
            <br />
            Esivalittuna kuvaaja käyttää kunkin yrityksen kohdalla viimeisimpiä järjestelmästä
            löytyviä taloustietoja.
            <br />
            <br />
            Tietolähteet: Profinder, Alma Talent
          </p>
        </ChartTable>
      </div>
    )
  }

  renderContent = () => {
    const { loading, companySizes } = this.props
    if (loading && !companySizes) {
      return <LoadingSpinner loading={loading} />
    }
    return this.renderSizeDistribution()
  }

  renderInfo = () => (
    <p>
      Yrityskokojakauma esittää kunnittain, tilinpäätösvuosittain ja toimialoittain eri
      yrityskokojen kokonaismäärän seuraavasti:
      <br />
      <br />
      Mikroyritys: Alle 10 työntekijää
      <br />
      <br />
      PK-yritys: 10 - 249 työntekijää
      <br />
      <br />
      Suuryritys: Yli 250 työntekijää
      <br />
      <br />
      Esivalittuna kuvaaja käyttää kunkin yrityksen kohdalla viimeisimpiä järjestelmästä löytyviä
      taloustietoja.
      <br />
      <br />
      Tietolähteet: Profinder, Alma Talent
    </p>
  )

  render() {
    return (
      <DashboardCard
        title="Yrityskokojakauma"
        renderModal={<InfoModal position="left">{this.renderInfo()}</InfoModal>}
      >
        {this.renderContent()}
      </DashboardCard>
    )
  }
}

CompanySizeDistribution.defaultProps = {
  companySizes: null,
}

CompanySizeDistribution.propTypes = {
  companySizes: PropTypes.shape(),
  loading: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    companySizes: state.chartStatistics.data.companySizes,
  }
}

export default connect(mapStateToProps)(CompanySizeDistribution)
