import React, { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import SearchResult from "../components/SearchResult"
import { globalSearch, populateStateWithQuery } from "../actions/searchActions"
import { getInitial, formatNumber } from "../util/string"
import LoadingSpinner from "../components/LoadingSpinner"
import { AdditionalFilters } from "../components/AdditionalFilters"
import Styles from "./SearchResults.module.css"
import { scrolledToBottomListener } from "../util/handleScrollToBottom"
import { Seo } from "../components/Seo"

const excelIcon = require("../assets/excel.svg")

class SearchResults extends Component {
  state = {
    items: [],
  }

  componentDidMount() {
    this.props.populateState(this.props.location)
    this.initScrollHandler()
  }

  componentDidUpdate(prevProps) {
    const { searchResult, location, searching } = this.props

    if (location !== prevProps.location) {
      this.handleNewSearch()
    }

    if (!searching && prevProps.searching !== searching) {
      this.clearItems()
      if (searchResult.length) {
        this.appendRenderableItems(0, 50)
      }
    }
  }

  componentWillUnmount() {
    this._scrollListener.stop()
  }

  handleNewSearch = () => {
    const { search, searchString, filters } = this.props

    this.clearItems()
    search(searchString, filters)
  }

  clearItems = () => {
    this.setState({ items: [] })
  }

  initScrollHandler = () => {
    this._scrollListener = scrolledToBottomListener(() => {
      const { items } = this.state
      this.appendRenderableItems(items.length, items.length + 50)
    })
    this._scrollListener.start()
  }

  appendRenderableItems = (start, end) => {
    const { searchResult } = this.props
    const { items } = this.state
    if (start === 0) return this.setState({ items: searchResult.slice(start, end) })
    this.setState({ items: items.concat(searchResult.slice(start, end)) })
  }

  renderResults = () => {
    const { location, searching } = this.props
    const { items } = this.state

    if (searching) return <LoadingSpinner loading={searching} />

    return items.map((result, i, arr) => {
      if (
        i === 0 ||
        getInitial(result.virallinen_nimi) !== getInitial(arr[i - 1].virallinen_nimi)
      ) {
        return (
          <div key={result.toimipaikkaId || result.y_tunnus}>
            <div className={Styles.divider}>
              <h2>
                <span>{getInitial(result.virallinen_nimi)}</span>
              </h2>
            </div>
            <SearchResult
              key={result.y_tunnus || result.toimipaikkaId}
              result={result}
              location={location}
            />
          </div>
        )
      }
      return (
        <SearchResult
          key={result.y_tunnus || result.toimipaikkaId}
          result={result}
          location={location}
        />
      )
    })
  }

  // TODO: Uncomment when needed
  // handleDownloadExcel = () => {
  //   const { match, location } = this.props;
  //   const { searchValue } = match.params;
  //   downloadExcel(searchValue, location.search);
  // };

  // Forms filter substring, query[0] is keyword and query[1] the value
  formSub = query => {
    if (query[0] === "personnels[]" || query[0] === "revenues[]") {
      if (query[1] === "-1,-1") return "Ei tiedossa"
      return query[1]
        .split(",")
        .map(n => formatNumber(n))
        .join(" - ")
    }
    return query[1].toLowerCase()
  }

  renderSearchFilters = () => {
    const { location } = this.props
    const splitQueryStrings = location.search.split("&").map(string => decodeURI(string))
    const keywords = {
      "region[]": "alue",
      "industry[]": "toimiala",
      "service[]": "palvelu",
      "form[]": "yhtiömuoto",
      startYearFrom: "vuodesta",
      startYearTo: "vuoteen",
      "personnels[]": "henkilöstö",
      "revenues[]": "liikevaihto",
    }
    if (splitQueryStrings[0].length > 1) {
      return (
        <div className={Styles.usedFilters}>
          {splitQueryStrings.map((query, i) => {
            const splitSingleQuery = query.replace("?", "").split("=")
            return (
              <div key={splitSingleQuery} className={Styles.filter}>
                <p>
                  <strong>{`${keywords[splitSingleQuery[0]]}: `}</strong>
                  {this.formSub(splitSingleQuery)}
                </p>
                {i + 1 !== splitQueryStrings.length && (
                  <span className={Styles.filterSeparator}>+</span>
                )}
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  renderExcelButton = visible =>
    visible ? (
      <button type="button" className={Styles.excelButton} onClick={this.handleDownloadExcel}>
        <img src={excelIcon} alt="excel-icon" className={Styles.excelIcon} />
        <p className={Styles.text}>Lataa Excel-tiedostona</p>
      </button>
    ) : null

  getPageTitle = () => {
    const {
      match: {
        params: { searchValue },
      },
    } = this.props
    let keyword = searchValue && searchValue !== '-' ? `"${searchValue}"` : '';

    return `Hakutulokset ${keyword}`
  }
  render() {
    const { searching, searchResult } = this.props

    return (
      <div className={[Styles.pageContent, Styles.resultScreen].join(" ")}>
        <div>
          <div className={Styles.backButton}>
            <Link to="/">← Takaisin etusivulle</Link>
          </div>
          <div className={Styles.searchInfoWrapper}>
            {searching ? null : (
              <div className={Styles.searchInfo}>
                Haulla löytyi
                <span className={Styles.searchInfoResult}>{` ${searchResult.length} `}</span>
                {searchResult.length === 1 ? "tulos" : "tulosta"}
              </div>
            )}

            {/* TODO: display when ready */}
            {/* {this.renderExcelButton(searchResult.length)} */}
          </div>
          {this.renderSearchFilters()}
          <div className={Styles.resultWrapper}>
            <AdditionalFilters />
            <div className={Styles.results}>{this.renderResults()}</div>
          </div>
        </div>
        <Seo title={this.getPageTitle()} />
      </div>
    )
  }
}

SearchResults.propTypes = {
  search: PropTypes.func.isRequired,
  populateState: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  searchResult: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  match: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  searchedValue: PropTypes.string,
  searchString: PropTypes.string.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired,
}

const mapStateToProps = state => ({
  searching: state.search.loading,
  searchResult: state.search.result,
  searchedValue: state.search.value,
  filters: state.search.filters,
  searchString: state.search.searchString,
})

const mapDispatchToProps = dispatch => ({
  search: (ss, f) => dispatch(globalSearch(ss, f)),
  populateState: location => dispatch(populateStateWithQuery(location)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchResults))
