import React, { Component } from "react"
import { Seo } from "../components/Seo"
import Styles from "./TextContent.module.css"

class FAQ extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <section className={[Styles.pageContent, Styles.container].join(" ")}>
        <h2>Usein kysytyt kysymykset</h2>
        <h3>Verotiedot</h3>
        <p>
          Yritystietopalvelussa käytetään Verohallinnon avoimena datana tarjoamaa tietoa yritysten
          maksuunpannuista veroista yhteensä. Tiedot esitetään yrityksen ilmoittaman kotikunnan ja
          verovuoden mukaan. Tieto päivittyy kerran vuodessa.
        </p>
        <p>
          Palveluun on toivottu myös tietoa suurimmista yhteisöveron maksajista kunnittain. Tieto ei
          ole avointa, joten sitä ei pystytä esittämään palvelussa.
        </p>
        <h3>Aktiivisen yrityksen määritelmä</h3>
        <p>
          Aktiivisesta yrityksen määritelmä vaihtelee eri palveluissa. Business Tampereen
          yritystietopalvelussa yritys luokittuu aktiiviseksi, kun se on rekisteröity
          kaupparekisteriin sekä vähintään yhteen toiseen rekisteriin (ALV-rekisteriin,
          ennakkoperintärekisteriin, tai työnantajarekisteriin) tai yrityksellä on tuore
          tilinpäätös, eikä sillä ole mitään erityistilannetta (konkurssi, saneeraus, selvitystila,
          toiminta keskeytynyt tai toiminta lakannut). Viimeisen kuuden kuukauden sisään perustetut
          yritykset ovat automaattisesti aktiivisia.
          <br />
          <br />
          Joissain muissa palveluissa aktiivisuus voi täyttyä esimerkiksi yrityksen kuuluessa
          pelkästään Kaupparekisteriin, ja toisissa määritelmä voi edellyttää kuulumista useaan
          muuhun rekisteriin. Tiedot eivät ole tällöin vertailtavissa.
        </p>
        <h3>Yrityskanta</h3>
        <p>
          Yritystietopalvelussa esitetty yrityskanta koostuu yrityksistä, jotka ovat
          rekisteröityneet Kaupparekisteriin, ja joiden yhtiömuoto on osakeyhtiö, avoin yhtiö,
          osuuskunta, kommandiittiyhtiö, julkinen osakeyhtiö tai yksityinen elinkeinoharjoittaja.{" "}
        </p>
        <h3>Yrityksen sijainnin määrittyminen </h3>
        <p>
          Yrityksen kotikunta perustuu yrityksen YTJ:än ilmoittamaan tietoon. Tietopalvelussa
          yrityksen sijainti määrittyy sen kotikunnan mukaan. Lisäksi palvelua on rikastettu
          erikseen hankitulla sivutoimipaikkatiedolla.
        </p>
        <p>
          Joissain muissa palveluissa sijaintitietona käytetään yrityksen käyntiosoitetta, mikä
          saattaa poiketa kotikunnasta.
        </p>
        <h3>Päätoimipaikat ja sivutoimipaikat</h3>
        <p>
          Useilla yrityksillä on päätoimipaikkansa lisäksi sivutoimipaikkoja. Tilastoissa saattaa
          kuitenkin olla niin, että yrityksen toiminta ja taloudellinen aktiivisuus kirjautuu
          pelkästään päätoimipaikan mukaan. Tämän vuoksi yritystietopalvelua on rikastettu erikseen
          hankitulla sivutoimipaikkatiedolla, joka näkyy muun muassa yrityslistauksissa ja
          karttakuvaajissa.
        </p>
        <h3>Palvelun ylläpito ja yhteyshenkilöt </h3>
        <p>
          Palvelua kehitetään Business Tampereessa yhteistyössä alueen elinkeinotoimijoiden,
          yritystiedon käyttäjien ja Pirkanmaan liiton analytiikkayhteistyön kanssa. Palvelun
          tekninen kehittämiskumppani on Geniem Oy.
        </p>
        <h4>Haluatko kuulla lisää tai osallistua palvelun kehittämiseen? </h4>
        <p>Ota yhteyttä:</p>
        <span>Noora Vaaraniemi</span>
        <span>Palveluvastaava, Business Tampere</span>
        <a href="mailto:noora.vaaraniemi@businesstampere.com">
          noora.vaaraniemi@businesstampere.com
        </a>
        <span>p. 041-4350883</span>
        <Seo title="Usein kysytyt kysymykset" />
      </section>
    )
  }
}

export default FAQ
