import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import classes from "./AdditionalFilters.module.css"
import Checkbox from "../Checkbox/Checkbox"
import Radio from "../Radio/Radio"
import * as SearchActions from "../../actions/searchActions"
import { Select } from "../Select"
import { buildSearchUrl } from "../../util/string"
import { useHistory } from "react-router-dom"

const Immutable = require("seamless-immutable").static

const AdditionalFilters = props => {
  const { options, profinderIndustries, filters, onFilterChange, search, searchString } = props
  const history = useHistory()

  const handleFilterChange = (element, filter) => {
    const newFilters = Immutable.asMutable(filters)
    if (filter !== "industry" && filter !== "service") {
      if (typeof element === "boolean") {
        newFilters[filter] = element
        return onFilterChange(newFilters)
      }
      if (newFilters[filter].some(f => f.label === element.label)) {
        newFilters[filter] = newFilters[filter].filter(f => f.label !== element.label)
      } else {
        newFilters[filter] = newFilters[filter].concat([element])
      }
    } else if (filter === "service") {
      newFilters.service = element || []
    } else {
      newFilters.industry = element || []
    }

    search(searchString, { ...newFilters })
    onFilterChange(newFilters)
    window.setTimeout(() => {
      history.push(buildSearchUrl(searchString, { ...newFilters }))
    })
  }

  const renderActive = () => {
    return null
    /*
    const { active } = filters
    if (active === undefined) return
    return (
      <React.Fragment>
        <h4 className={classes.filterHeader}>Näytä yritykset</h4>
        <ul className={classes.filterOptions}>
          <li className={classes.filter}>
            <Radio
              label="Vain aktiiviset"
              name="active"
              checked={active}
              onClick={() => {
                onFilterChange({ active: true })
                search(searchString, { ...filters, active: true })
              }}
            />
          </li>
          <li className={classes.filter}>
            <Radio
              name="active"
              label="Koko yrityskanta"
              checked={!active}
              onClick={() => {
                onFilterChange({ active: false })
                search(searchString, { ...filters, active: false })
              }}
            />
          </li>
        </ul>
      </React.Fragment>
    )
    */
  }

  const renderRegions = () => {
    const { regionOptions } = options
    if (!regionOptions) return null
    return (
      <React.Fragment>
        <h4 className={classes.filterHeader}>Alueet</h4>
        <ul className={classes.filterOptions}>
          {regionOptions.map(ro => (
            <li key={ro.value} className={classes.filter}>
              <Checkbox
                label={ro.label}
                checked={filters.region.some(f => f.value === ro.value)}
                onClick={() => handleFilterChange(ro, "region")}
                id={`${ro.label}-sidebar`}
              />
            </li>
          ))}
        </ul>
      </React.Fragment>
    )
  }

  const renderBranches = () => {
    const { companies, branches } = filters
    return (
      <React.Fragment>
        <h4 className={classes.filterHeader}>Toimipaikat</h4>
        <ul className={classes.filterOptions}>
          <li className={classes.filter}>
            <Radio
              label="Kaikki"
              name="branches"
              checked={companies && branches}
              onClick={() => {
                onFilterChange({ companies: true, branches: true })
                search(searchString, { ...filters, companies: true, branches: true })
              }}
            />
          </li>
          <li className={classes.filter}>
            <Radio
              label="Vain päätoimipaikat"
              colorCode="#d15371"
              name="branches"
              checked={companies && !branches}
              onClick={() => {
                onFilterChange({ companies: true, branches: false })
                search(searchString, { ...filters, companies: true, branches: false })
              }}
            />
          </li>
          <li className={classes.filter}>
            <Radio
              label="Vain sivutoimipaikat"
              colorCode="#7a65a7"
              name="branches"
              checked={branches && !companies}
              onClick={() => {
                onFilterChange({ companies: false, branches: true })
                search(searchString, { ...filters, companies: false, branches: true })
              }}
            />
          </li>
        </ul>
      </React.Fragment>
    )
  }

  const renderIndustries = () => {
    const { industryOptions } = options
    if (!industryOptions) return null

    return (
      <React.Fragment>
        <h4 className={`${classes.filterHeader} ${classes.industryHeader}`}>Toimialat</h4>
        <div>
          <Select
            options={options.industryOptions && options.industryOptions.asMutable()}
            onChange={e => handleFilterChange(e, "industry")}
            isMulti
            value={filters.industry.asMutable()}
          />
        </div>
      </React.Fragment>
    )
  }

  const renderServices = () => {
    if (!profinderIndustries) return null
    console.log(JSON.stringify(profinderIndustries))
    const pfOpts = profinderIndustries.asMutable()


    return (
      <React.Fragment>
        <h4 className={`${classes.filterHeader} ${classes.industryHeader}`}>Palvelut</h4>
        <div>
          <Select
            options={pfOpts}
            onChange={e => handleFilterChange(e, "service")}
            isMulti
            value={filters.service.asMutable()}
          />
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className={classes.container}>
      <h3 className={classes.header}>Valitut hakuehdot</h3>
      {renderActive()}
      {renderRegions()}
      {renderBranches()}
      {renderIndustries()}
      {renderServices()}
    </div>
  )
}

AdditionalFilters.propTypes = {
  options: PropTypes.shape().isRequired,
  profinderIndustries: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  onFilterChange: PropTypes.func.isRequired,
  searchString: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  options: state.filterOptions.data,
  profinderIndustries: state.profinderIndustries.data,
  filters: state.search.filters,
  searchString: state.search.searchString,
})

const mapDispatchToProps = dispatch => ({
  onFilterChange: newFilters => dispatch(SearchActions.handleFilterChange(newFilters)),
  search: (ss, f) => dispatch(SearchActions.globalSearch(ss, f)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalFilters)
