import React, { Component } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "react-flexbox-grid"
import { connect } from "react-redux"
import DashboardCard from "./DashboardCard"
import RdiFunding from "../DashboardCharts/RdiFunding/RdiFunding"
import Styles from "./DashboardCards.module.css"
import * as RevenueListActions from "../../actions/revenueListActions"
import * as EmployerListActions from "../../actions/employerListActions"
import * as TaxListActions from "../../actions/taxListActions"
import * as FundingActions from "../../actions/fundingActions"
import Top10RevenueWidget from "../RevenueWidget/Top10RevenueWidget"
import Top10Employers from "../Top10Employers/Top10Employers"
import Top10TaxPayers from "../Top10TaxPayers/Top10TaxPayers"
import CompanyTypes from "../DashboardCharts/CompanyTypes/CompanyTypes"
import Turnover from "../DashboardCharts/Turnover/Turnover"
import Top10Industries from "../DashboardCharts/Top10Industries/Top10Industries"
import SetUpCompany from "../DashboardCharts/SetUpCompany/SetUpCompany"
import CompanySizeDistribution from "../DashboardCharts/CompanySizeDistribution/CompanySizeDistribution"
import CompaniesOnMap from "../CompaniesOnMap"
import InfoModal from "../InfoModal"
import { formatMoney } from "../../util/string"
import { REVENUE_LIST_YEAR, TAX_LIST_YEAR, EMPLOYER_LIST_YEAR } from "../../util/constants"

class DashboardCards extends Component {
  revenueRef = React.createRef()
  employerRef = React.createRef()
  taxRef = React.createRef()

  componentDidMount() {
    const {
      location,
      changeRevenueParams,
      clearRevenueParams,
      revenueParams,
      changeEmployerParams,
      clearEmployerParams,
      employerParams,
      changeTaxParams,
      clearTaxParams,
      taxParams,
      fetchFunding,
      history,
      filters,
    } = this.props

    // If user navigates back from a widget result, use params from store, scroll to widget,
    // and remove prevPath from location state
    if (
      location.state &&
      (location.state.prevPath.includes("/companies/") ||
        location.state.prevPath.includes("/top500/"))
    ) {
      changeRevenueParams({ ...revenueParams, ...filters })
      changeEmployerParams({ ...employerParams, ...filters })
      changeTaxParams({ ...taxParams, ...filters })
      this.scrollToWidgets()
      return history.replace("", undefined)
    } else {
      clearRevenueParams(filters)
      clearEmployerParams(filters)
      clearTaxParams(filters)
    }
    fetchFunding(this.props.fundingYear)
  }

  scrollToWidgets = () => {
    if (this.revenueRef.current) {
      window.scrollTo(0, this.revenueRef.current.offsetTop)
    }
  }

  scrollToWidgetEmployer = () => {
    if (this.employerRef.current) {
      window.scrollTo(0, this.employerRef.current.offsetTop)
    }
  }

  scrollToWidgetTax = () => {
    if (this.taxRef.current) {
      window.scrollTo(0, this.taxRef.current.offsetTop)
    }
  }

  render() {
    const {
      firstYearFunding,
      secondYearFunding,
      firstYearLoans,
      secondYearLoans,
      loadingFunding,
      loadingTop10,
      loadingTop10Empl,
      loadingTop10Tax,
      top10revenue,
      top10employer,
      top10taxPayers,
      industryOptions,
      location,
      revenueParams,
      employerParams,
      taxParams,
      loadingChartStatistics,
      fundingYear,
    } = this.props

    const mappedTop10Revenue = top10revenue.map(company => ({
      ...company,
      value: `${Number(company.lvkasvupros).toFixed(2)}%`,
    }))

    const mappedTop10Employers = top10employer.map(company => ({
      ...company,
      value: company.hlokeskim,
    }))

    const mappedTop10TaxPayers = top10taxPayers.map(company => ({
      ...company,
      value: formatMoney(company.maksuunpannut_verot),
    }))

    return (
      <Row className={Styles.content}>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <CompanyTypes loading={loadingChartStatistics} />
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <Turnover loading={loadingChartStatistics} />
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <Top10Industries loading={loadingChartStatistics} />
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <SetUpCompany loading={loadingChartStatistics} />
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <CompanySizeDistribution loading={loadingChartStatistics} />
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <RdiFunding
            firstYearFunding={firstYearFunding}
            secondYearFunding={secondYearFunding}
            firstYearLoans={firstYearLoans}
            secondYearLoans={secondYearLoans}
            loading={loadingFunding}
            currentYear={fundingYear}
          />
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <DashboardCard
            forwardRef={this.employerRef}
            title="Top 10 suurimmat työllistäjät"
            renderModal={
              <InfoModal position="left">
                <p>
                  Yritysten työntekijöiden määrät tulevat Profinderin ja Alma Talentin toimittamista
                  vuosikohtaisista taloustiedoista.
                  <br />
                  <br />
                  {`Esivalittuna taulukko näyttää yli 1 000 000 euron liikevaihdon yritykset vuoden ${EMPLOYER_LIST_YEAR} tilastoista.`}
                  <br />
                  <br />
                  Tietolähteet: Profinder, Alma Talent
                </p>
              </InfoModal>
            }
          >
            <Top10Employers
              list={mappedTop10Employers}
              loading={loadingTop10Empl}
              industries={industryOptions}
              location={location}
              params={employerParams}
            />
          </DashboardCard>
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <DashboardCard
            forwardRef={this.revenueRef}
            title="top 10 liikevaihdon kasvattajat"
            renderModal={
              <InfoModal position="left">
                <p>
                  Liikevaihdon muutosprosentti kuvaa liikevaihdon kasvua tai laskua yrityksen
                  edelliseen tilikauteen nähden.
                  <br />
                  <br />
                  {`Esivalittuna taulukko näyttää yli 1 000 000 euron liikevaihdon yritykset vuoden ${REVENUE_LIST_YEAR} tilastoista.`}
                  <br />
                  <br />
                  Tietolähteet: Profinder, Alma Talent
                </p>
              </InfoModal>
            }
          >
            <Top10RevenueWidget
              list={mappedTop10Revenue}
              loading={loadingTop10}
              industries={industryOptions}
              location={location}
              params={revenueParams}
            />
          </DashboardCard>
        </Col>
        <Col className={Styles.col} xs={12} sm={4} md={4}>
          <DashboardCard
            forwardRef={this.taxRef}
            title="Top 10 yritysten maksamat verot"
            renderModal={
              <InfoModal position="left">
                <p>
                  Vuosikohtaiset yhteisöjen tuloverotustiedot on kerätty Verohallinnon julkaisemasta
                  avoimesta datasta.
                  <br />
                  {`Järjestelmässä on tällä hetkellä verotiedot vuosilta 2016-${TAX_LIST_YEAR}.`}
                  <br />
                  <br />
                  {`Esivalittuna taulukko näyttää yli 1 000 000 euron liikevaihdon yritykset vuoden ${TAX_LIST_YEAR} tilastoista.`}
                  <br />
                  <br />
                  Tietolähde: Verohallinto
                </p>
              </InfoModal>
            }
          >
            <Top10TaxPayers
              list={mappedTop10TaxPayers}
              loading={loadingTop10Tax}
              industries={industryOptions}
              location={location}
              params={taxParams}
            />
          </DashboardCard>
        </Col>
        <Col className={Styles.col} xs={112} sm={12} md={12}>
          <CompaniesOnMap location={location} />
        </Col>
      </Row>
    )
  }
}

DashboardCards.propTypes = {
  fetchFunding: PropTypes.func.isRequired,
  firstYearFunding: PropTypes.number.isRequired,
  secondYearFunding: PropTypes.number.isRequired,
  firstYearLoans: PropTypes.number.isRequired,
  secondYearLoans: PropTypes.number.isRequired,
  loadingFunding: PropTypes.bool.isRequired,
  loadingTop10: PropTypes.bool.isRequired,
  loadingTop10Empl: PropTypes.bool.isRequired,
  loadingTop10Tax: PropTypes.bool.isRequired,
  loadingChartStatistics: PropTypes.bool.isRequired,
  top10revenue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  top10employer: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  top10taxPayers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  industryOptions: PropTypes.arrayOf(PropTypes.shape()),
  location: PropTypes.shape().isRequired,
  revenueParams: PropTypes.shape().isRequired,
  employerParams: PropTypes.shape().isRequired,
  taxParams: PropTypes.shape().isRequired,
  changeRevenueParams: PropTypes.func.isRequired,
  clearRevenueParams: PropTypes.func.isRequired,
  changeEmployerParams: PropTypes.func.isRequired,
  clearEmployerParams: PropTypes.func.isRequired,
  changeTaxParams: PropTypes.func.isRequired,
  clearTaxParams: PropTypes.func.isRequired,
  history: PropTypes.shape().isRequired,
  filters: PropTypes.shape().isRequired,
  fundingYear: PropTypes.number.isRequired,
}

DashboardCards.defaultProps = {
  industryOptions: [],
}

const mapStateToProps = state => ({
  loadingTop10: state.revenueList.loading,
  loadingTop10Empl: state.employerList.loading,
  loadingTop10Tax: state.taxList.loading,
  loadingChartStatistics: state.chartStatistics.loading,
  top10revenue: state.revenueList.data,
  top10employer: state.employerList.data,
  top10taxPayers: state.taxList.data,
  loadingRegionStatistics: state.regionStatistics.loading,
  regionStatistics: state.regionStatistics.data,
  searching: state.search.loading,
  searchResult: state.search.result,
  industryOptions: state.filterOptions.data.industryOptions,
  revenueParams: state.revenueList.params,
  employerParams: state.employerList.params,
  taxParams: state.taxList.params,
  loadingFunding: state.funding.loading,
  fundingParams: state.funding.params,
  firstYearFunding: state.funding.firstYearFunding,
  secondYearFunding: state.funding.secondYearFunding,
  firstYearLoans: state.funding.firstYearLoans,
  secondYearLoans: state.funding.secondYearLoans,
  fundingYear: state.funding.params.year,
})

const mapDispatchToProps = dispatch => ({
  changeRevenueParams: params => dispatch(RevenueListActions.changeParams(params)),
  changeEmployerParams: params => dispatch(EmployerListActions.changeParams(params)),
  changeTaxParams: params => dispatch(TaxListActions.changeParams(params)),
  clearRevenueParams: filters => dispatch(RevenueListActions.clearParams(filters)),
  clearTaxParams: filters => dispatch(TaxListActions.clearParams(filters)),
  clearEmployerParams: filters => dispatch(EmployerListActions.clearParams(filters)),
  fetchFunding: params => dispatch(FundingActions.fetchFunding(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCards)
