import * as api from "../services/api"

export const FETCH_CHART_STATISTICS = "FETCH_CHART_STATISTICS"
export const FETCH_CHART_STATISTICS_OK = "FETCH_CHART_STATISTICS_OK"
export const FETCH_CHART_STATISTICS_ERROR = "FETCH_CHART_STATISTICS_ERROR"

export const fetchChartStatistics = (region, industry, year) => async dispatch => {
  dispatch({ type: FETCH_CHART_STATISTICS })

  const response = await api.getChartStatistics(region, industry, year)
  if (response.ok) {
    dispatch({
      type: FETCH_CHART_STATISTICS_OK,
      data: response.data,
    })
  } else {
    dispatch({
      type: FETCH_CHART_STATISTICS_ERROR,
      error: response.data,
    })
  }
}
