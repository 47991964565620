import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import ReactMapGL, {
  Popup,
  Source,
  Layer,
  MapLoadEvent,
  PointerEvent,
  WebMercatorViewport,
  FlyToInterpolator,
} from "react-map-gl"
import classes from "./Map.module.css"
import MapStyles from "../../assets/Streets.json"
import {
  clusterLayer,
  clusterCountLayer,
  unclusteredPointLayer,
  unclesteredBranchPointLayer,
} from "./Layers"
import { companiesToGeojson } from "../../util/companiesToGeojson"

import MarkerPink from "../../assets/MarkerPink.svg"
import MarkerPurple from "../../assets/MarkerPurple.svg"

export class Map extends React.PureComponent {
  state = {
    viewport: {
      latitude: 61.4977344,
      longitude: 23.6907433,
      zoom: 9,
      bearing: 0,
      pitch: 0,
    },
    popupInfo: null,
  }

  sourceRef = React.createRef()

  componentDidUpdate = prevProps => {
    if (prevProps.companies !== this.props.companies) {
      this.setState({ popupInfo: null })
      this.fitCompaniesToViewport()
    }
  }

  handleMapLoaded = (event: MapLoadEvent) => {
    const markerPink = new Image(37 * 1.75, 48 * 1.75)
    markerPink.src = MarkerPink
    markerPink.className = classes.marker
    markerPink.onload = () => event.target.addImage("marker-pink", markerPink, { pixelRatio: 2 })

    const markerPurple = new Image(37 * 1.75, 48 * 1.75)
    markerPurple.src = MarkerPurple
    markerPurple.className = classes.marker
    markerPurple.onload = () =>
      event.target.addImage("marker-purple", markerPurple, { pixelRatio: 2 })

    this.fitCompaniesToViewport()
  }

  data = () =>
    [...this.props.companies]
      .concat(...this.props.companies.map(c => c.branches || c.company))
      .filter(c => c && c.latitude && c.longitude)

  fitCompaniesToViewport = () => {
    const { viewport } = this.state
    const data = this.data()
    if (!data || !data.length) return
    const lngs = data.map(c => Number(c.longitude)).filter(lng => lng !== 0)
    const lats = data.map(c => Number(c.latitude)).filter(lat => lat !== 0)
    if (!lngs.length || !lats.length) return
    const minLng = Math.min(...lngs)
    const minLat = Math.min(...lats)
    const maxLng = Math.max(...lngs)
    const maxLat = Math.max(...lats)
    const { longitude, latitude, zoom } = new WebMercatorViewport(viewport).fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: 20,
        offset: [0, -20],
      }
    )
    const newViewport = {
      ...viewport,
      longitude,
      latitude,
      zoom: lngs.length === 1 ? 14 : zoom,
      transitionDuration: 600,
      transitionInterpolator: new FlyToInterpolator(),
    }
    this.onViewportChange(newViewport)
  }

  onViewportChange = viewport => {
    this.setState({ viewport })
  }

  onClickMarker = company => this.setState({ popupInfo: company })

  hideMarker = () => this.setState({ popupInfo: null })

  handleMarkerClick = (event: PointerEvent) => {
    if (!event.features || !event.features.length) return this.hideMarker()

    const feature = event.features[0]
    // Separately handle marker click
    if (feature.layer.id === unclusteredPointLayer.id) {
      return this.props.showPopup && this.onClickMarker(feature.properties)
    } else if (feature.layer.id === unclesteredBranchPointLayer.id) {
      return this.onClickMarker(feature.properties)
    }
    // Otherwise zoom to next cluster expansion zoom level
    const clusterId = feature.properties.cluster_id

    const mapboxSource = this.sourceRef.current.getSource()

    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
      if (err) return

      this.onViewportChange({
        ...this.state.viewport,
        longitude: feature.geometry.coordinates[0],
        latitude: feature.geometry.coordinates[1],
        zoom,
        transitionDuration: 500,
      })
    })
  }

  renderPopup() {
    const { popupInfo } = this.state

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="bottom"
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
          className={classes.popup}
          offsetTop={-40}
        >
          <div className={classes.popupInfo}>
            <Link
              className={classes.companyName}
              to={
                popupInfo.toimipaikkaId
                  ? `/sivutoimipaikka/${popupInfo.toimipaikkaId}`
                  : `/yritys/${popupInfo.y_tunnus || popupInfo.ytunnus}`
              }
            >
              {popupInfo.toimipaikkaId ? popupInfo.toimipaikka_nimi : popupInfo.virallinen_nimi}
            </Link>
          </div>
        </Popup>
      )
    )
  }

  render() {
    const { viewport } = this.state

    return (
      <div className={classes.mapWrapper}>
        <ReactMapGL
          {...viewport}
          width="100%"
          height="100%"
          mapStyle={MapStyles}
          onViewportChange={this.onViewportChange}
          onClick={this.handleMarkerClick}
          onLoad={this.handleMapLoaded}
          interactiveLayerIds={[
            clusterLayer.id,
            unclusteredPointLayer.id,
            unclesteredBranchPointLayer.id,
          ]}
        >
          <Source
            id="locations"
            type="geojson"
            data={companiesToGeojson(this.data())}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
            ref={this.sourceRef}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
            <Layer {...unclesteredBranchPointLayer} />
          </Source>
          {this.renderPopup()}
        </ReactMapGL>
      </div>
    )
  }
}

Map.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.shape()),
  showPopup: PropTypes.bool,
}

Map.defaultProps = {
  showPopup: true,
}
