import { create } from "apisauce"
// import fileDownload from "js-file-download"

function getBaseUrl() {
  if (window.location && window.location.hostname) {
    if (window.location.hostname.includes("localhost") || process.env.NODE_ENV === "development") {
      // return "https://trebusiness-api-do.stage.geniem.io/"
      return "http://localhost:3001/"
    }
    if (window.location.hostname.includes("stage")) {
      return "https://trebusiness-api-do.stage.geniem.io/"
    }
  }
  // Use production api
  return "https://trebusiness-api.production.geniem.io/"
}

const api = create({
  // TODO variables from .env
  baseURL: getBaseUrl(),
  headers: {
    apikey: process.env.REACT_APP_API_KEY,
    "Content-Type": "application/json",
  },
})

export const searchByValue = async (searchstring, filters) =>
  api.get(`widgets/search/${searchstring}`, { ...filters })

export const getRevenueList = async ({
  minRevenue = 1000000,
  maxRevenue = 100000000000000,
  region = "%",
  industry = "%",
  limit = 10,
  direction = "DESC",
  year = new Date().getFullYear(),
}) =>
  api.get("widgets/revenue-list", {
    minRevenue,
    maxRevenue,
    region,
    industry,
    limit,
    direction,
    year,
  })

export const getEmployerList = async ({
  minRevenue = 1000000,
  maxRevenue = 100000000000000,
  region = "%",
  industry = "%",
  limit = 10,
  direction = "DESC",
  year = new Date().getFullYear(),
}) =>
  api.get("widgets/employer-list", {
    minRevenue,
    maxRevenue,
    region,
    industry,
    limit,
    direction,
    year,
  })

export const getTaxList = async ({
  minRevenue = 1000000,
  maxRevenue = 100000000000000,
  region = "%",
  industry = "%",
  limit = 10,
  direction = "DESC",
  year = new Date().getFullYear(),
}) =>
  api.get("widgets/tax-list", {
    minRevenue,
    maxRevenue,
    region,
    industry,
    limit,
    direction,
    year,
  })

export const getChartStatistics = async (region, industry, year) =>
  api.get("widgets/dashboard", { region, industry, year })
export const getRegionStatistics = async (region, industry, year) =>
  api.get("widgets/region-info", { region, industry, year })

export const getCompany = async businessId => api.get(`companies/${businessId}`)

export const getBranch = async branchId => api.get(`branches/${branchId}`)

export const getIndustries = async () => api.get("industries")

export const getSearchFilters = async () => api.get("widgets/search-filters")

// TODO: Uncomment when needed
// export const downloadExcel = (keyword, search) => {
//   const url = `${getBaseUrl()}companies/export-search-results/${keyword}${search}`
//   axios.get(url, { headers: { apikey }, responseType: "blob" }).then(response => {
//     fileDownload(response.data, "hakutiedot.xlsx")
//   })
// }

export const getFunding = async (region = "%", industry = "%", year = 2017) =>
  api.get("/widgets/funding-info", { region, industry, year })

// Profinder industries
export const getProfinderIndustries = async () => api.get("companies/pf-industry-types")
export const getProfinderBranchIndustries = async () => api.get("branches/pf-industry-types")
