export const revenueToNumber = (revenue: string) => {
  if (!revenue || typeof revenue !== "string") return revenue
  const rangeStart = revenue.split(" ")[0]
  if (rangeStart.charAt(rangeStart.length - 1) === "m") {
    return Number(rangeStart.substring(0, rangeStart.length - 1).concat("000000"))
  }
  if (rangeStart.charAt(rangeStart.length - 1) === "k") {
    return Number(rangeStart.substring(0, rangeStart.length - 1).concat("000"))
  }
  if (rangeStart.charAt(rangeStart.length - 1) === "i") {
    return 999999999
  }
}
