import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import "react-dropdown/style.css"
import { Select } from "./Select"
import { REGION_OPTIONS } from "../util/constants"
class RegionSelector extends PureComponent {
  render() {
    const { onChange, value } = this.props

    return (
      <Select
        value={value}
        id="region-select"
        options={REGION_OPTIONS}
        onChange={onChange}
        variant="borderless"
        placeholder="Kunta"
      />
    )
  }
}

RegionSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholderClassName: PropTypes.string,
}

RegionSelector.defaultProps = {
  className: "",
  placeholderClassName: "",
}

export default RegionSelector
