import * as api from "../services/api"
import * as uniq from "lodash.uniq"

export const FETCH_PROFINDER_INDUSTRIES = "FETCH_PROFINDER_INDUSTRIES"
export const FETCH_PROFINDER_INDUSTRIES_OK = "FETCH_PROFINDER_INDUSTRIES_OK"
export const FETCH_PROFINDER_INDUSTRIES_ERROR = "FETCH_PROFINDER_INDUSTRIES_ERROR"

export const fetchProfinderIndustries = () => async dispatch => {
  dispatch({ type: FETCH_PROFINDER_INDUSTRIES })

  const response = await api.getProfinderIndustries()
  const branchResponse = await api.getProfinderBranchIndustries()

  if (response.ok && branchResponse.ok) {
    const data = uniq([].concat(response.data, branchResponse.data)).sort()
      .map(industry => ({ label: industry, value: industry }));

    dispatch({
      type: FETCH_PROFINDER_INDUSTRIES_OK,
      data,
    })
  } else {
    dispatch({
      type: FETCH_PROFINDER_INDUSTRIES_ERROR,
      error: response.data,
    })
  }
}
