import * as api from "../services/api"
import { REVENUE_VALUES, EMPLOYER_LIST_YEAR } from "../util/constants"

export const FETCH_TOP10_EMPLOYERS = "FETCH_TOP10_EMPLOYERS"
export const FETCH_TOP10_EMPLOYERS_OK = "FETCH_TOP10_EMPLOYERS_OK"
export const FETCH_TOP10_EMPLOYERS_ERROR = "FETCH_TOP10_EMPLOYERS_ERROR"
export const FETCH_TOP10_EMPLOYERS_CHANGE_PARAMS = "FETCH_TOP10_EMPLOYERS_CHANGE_PARAMS"
export const FETCH_TOP10_EMPLOYERS_CLEAR_PARAMS = "FETCH_TOP10_EMPLOYERS_CLEAR_PARAMS"

export const fetchTop10Employers = params => async dispatch => {
  const {
    minRevenue = 1000000,
    maxRevenue = 100000000000000,
    region = "%",
    industry = "%",
    limit = 10,
    direction = "DESC",
    year = EMPLOYER_LIST_YEAR,
  } = params
  dispatch({ type: FETCH_TOP10_EMPLOYERS })
  const response = await api.getEmployerList({
    minRevenue,
    maxRevenue,
    region,
    industry,
    limit,
    direction,
    year: year !== 0 ? year : EMPLOYER_LIST_YEAR,
  })
  if (response.ok) {
    dispatch({
      type: FETCH_TOP10_EMPLOYERS_OK,
      data: response.data,
    })
  } else {
    dispatch({
      type: FETCH_TOP10_EMPLOYERS_ERROR,
      error: response.data,
    })
  }
}

export const changeParams = params => async dispatch => {
  const { revenue, region, industry, year } = params
  const rev = revenue === "" ? 1 : revenue

  dispatch({ type: FETCH_TOP10_EMPLOYERS_CHANGE_PARAMS, data: params })
  dispatch(
    fetchTop10Employers({
      minRevenue: REVENUE_VALUES[rev - 1].min,
      maxRevenue: REVENUE_VALUES[rev - 1].max,
      region,
      industry,
      year,
    })
  )
}

export const clearParams = filters => async dispatch => {
  dispatch({ type: FETCH_TOP10_EMPLOYERS_CLEAR_PARAMS })
  dispatch(fetchTop10Employers({ ...filters }))
}
