import React from "react"
import PropTypes from "prop-types"
import { dateToDdMmYy } from "../../util/date"
import Styles from "./Registry.module.css"
import RibbonContainer from "../RibbonContainer"

const checkmark = require("../../assets/checkmark.svg")
const crossmark = require("../../assets/crossmark.svg")

const ifInRegistry = registry =>
  registry ? (
    <p>
      <img src={checkmark} alt="" className={Styles.indicator} />
      <span className={Styles.inRegistry}>Rekisterissä</span>
    </p>
  ) : (
    <p>
      <img src={crossmark} alt="" className={Styles.indicator} />
      <span className={Styles.inRegistry}>Ei rekisterissä</span>
    </p>
  )

const registryDate = ({ inRegistry, startDate, endDate }) => {
  if (inRegistry) {
    return dateToDdMmYy(startDate)
  }
  if (endDate) {
    return dateToDdMmYy(endDate)
  }
  return "Ei tietoa"
}

const CompanyRegistry = ({ company }) => (
  <RibbonContainer title="rekisteritiedot">
    <div className={Styles.registry}>
      <table>
        <tbody>
          <tr>
            <td>Kaupparekisteri</td>
            <td>{ifInRegistry(company.kaupparekisteri)}</td>
            <td>
              {registryDate({
                inRegistry: company.kaupparekisteri,
                startDate: company.kaupparek_alkupvm,
                endDate: company.kaupparek_loppupvm,
              })}
            </td>
          </tr>
          <tr>
            <td>Arvonlisäverorekisteri</td>
            <td>{ifInRegistry(company.verohallinto)}</td>
            <td>
              {registryDate({
                inRegistry: company.verohallinto,
                startDate: company.verohallinto_alkupvm,
                endDate: company.verohallinto_loppupvm,
              })}
            </td>
          </tr>
          <tr>
            <td>Ennakkoperintärekisteri</td>
            <td>{ifInRegistry(company.ennakkoperintarekisteri)}</td>
            <td>
              {registryDate({
                inRegistry: company.ennakkoperintarekisteri,
                startDate: company.ennakkoperintarek_alkupvm,
                endDate: company.ennakkoperintarek_loppupvm,
              })}
            </td>
          </tr>
          <tr>
            <td>Työnantajarekisteri</td>
            <td>{ifInRegistry(company.tyonantajarekisteri)}</td>
            <td>
              {registryDate({
                inRegistry: company.tyonantajarekisteri,
                startDate: company.tyonantajarek_alkupvm,
                endDate: company.tyonantajarek_loppupvm,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </RibbonContainer>
)

CompanyRegistry.propTypes = {
  company: PropTypes.shape().isRequired,
}

export default CompanyRegistry
